import { Fragment, FunctionComponent } from "react";
import {
   Box,
   Button,
   Card,
   Typography,
   Link,
   Divider,
   Grid,
} from "@mui/material";
import { Union } from "../../slices/models";
import { useUser } from "../../hooks/use-user";
import VerifiedIcon from "@mui/icons-material/Verified";
import { paths } from "../../paths";
import { RouterLink } from "../../components/router-link";
import { Logo } from "../../components/logo";
interface Props {
   unions: Union[];
   businessKey: number;
   locationKey: number;
}

const BusinessUnionList: FunctionComponent<Props> = ({
   businessKey,
   unions,
   locationKey,
}) => {
   const { userKey } = useUser();

   if (!unions || unions.length == 0) return <></>;

   return (
      <>
         <Box sx={{ mt: 2 }}>
            <Card variant="outlined">
               {unions.map((union, index) => (
                  <Fragment key={union.unionKey}>
                     <Grid
                        key={union.unionKey}
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        sx={{
                           px: 2,
                           py: 1.5,
                        }}
                     >
                        <Grid item
xs={8}>
                           <Box
                              sx={{
                                 display: "flex",
                              }}
                           >
                              <Box
                                 sx={{
                                    display: "inline-flex",
                                    mr: 1,
                                    mt: 0.4,
                                    minHeight: 20,
                                    minWidth: 20,
                                    height: 20,
                                    width: 20,
                                 }}
                              >
                                 <Logo />
                              </Box>

                              <Link
                                 component={RouterLink}
                                 href={paths.union(
                                    locationKey,
                                    businessKey,
                                    union.unionKey,
                                 )}
                                 color="textPrimary"
                                 variant="h6"
                                 data-cy="union-link"
                              >
                                 <Typography variant="subtitle1">
                                    {union.name}
                                 </Typography>
                              </Link>

                              {union.locationKey == 0 && (
                                 <VerifiedIcon
                                    color="success"
                                    fontSize="small"
                                    sx={{ ml: 1 }}
                                 />
                              )}
                           </Box>
                           {union.members.length > 0 && (
                              <Typography
                                 color="textSecondary"
                                 variant="caption"
                              >
                                 <Typography color="inherit"
variant="caption">
                                    {union.members.length +
                                       " member" +
                                       (union.members.length == 1 ? " " : "s ")}
                                    {union.tagline != ""
                                       ? "• " + union.tagline + " • "
                                       : " • "}
                                    {union.monthlyDues > 0
                                       ? "Dues $" + union.monthlyDues + " /mo"
                                       : "No union dues"}
                                 </Typography>
                              </Typography>
                           )}
                        </Grid>
                        <Grid item
xs={4}>
                           <Grid container
justifyContent="flex-end">
                              {!union.members.find(
                                 (member) => member.userKey == userKey,
                              ) ? (
                                 <Link
                                    component={RouterLink}
                                    href={paths.joinUnion(
                                       locationKey,
                                       businessKey,
                                       union.unionKey,
                                    )}
                                 >
                                    <Button data-cy="join-union-button">
                                       Join
                                    </Button>
                                 </Link>
                              ) : (
                                 <Link
                                    component={RouterLink}
                                    href={paths.dashboard.index}
                                 >
                                    <Button>Dashboard</Button>
                                 </Link>
                              )}
                           </Grid>
                        </Grid>
                     </Grid>
                     {unions.length > index - 1 && <Divider />}
                  </Fragment>
               ))}
            </Card>
         </Box>
      </>
   );
};

export default BusinessUnionList;
