import {
   Box,
   Card,
   Container,
   Grid,
   Typography,
   Skeleton,
} from "@mui/material";
import { LocationOn as LocationOnIcon } from "@mui/icons-material";
import BusinessList from "../../sections/business/business-list";
import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import { dehydrate, QueryClient } from "@tanstack/query-core";
import {
   API,
   useGetLocation,
   useGetLocationBusinesses,
} from "../../hooks/use-api";
import { usePageView } from "../../hooks/use-page-view";
import { Layout as MarketingLayout } from "../../layouts/marketing";
import { GetServerSideProps } from "next/types";
import { NextPage } from "next";
import { Seo } from "../../components/seo";

const LocationMap = dynamic(() => import("../../sections/map/location-map"), {
   ssr: false,
   loading: () => <Skeleton variant="rounded"
animation="wave"
height={300} />,
});
const Location: NextPage = () => {
   usePageView();

   const router = useRouter();
   const locationKey = parseInt(router.query.locationKey as string);
   const { data: location } = useGetLocation({ locationKey });
   const { data: businesses } = useGetLocationBusinesses({ locationKey });

   const listFormatAddress = location ? location.listFormatAddress : "";

   return (
      <>
         <Seo
            title={listFormatAddress}
            description={`Join one of the local labor unions located at ${listFormatAddress.replaceAll(
               ",",
               "",
            )}, or create new one.`}
         ></Seo>
         <Box
            component="main"
            sx={{
               flexGrow: 1,
               mb: 10,
               backgroundRepeat: "no-repeat",
               backgroundPosition: "top center",
               backgroundImage: 'url("/assets/gradient-bg.svg")',
               pt: "120px",
            }}
         >
            <Container maxWidth="lg">
               <Box
                  sx={{
                     mb: 2,
                  }}
                  data-cy={"address"}
               >
                  <Card
                     key={"activity.id"}
                     sx={{
                        alignItems: "center",
                        display: "flex",
                        p: 1,
                        "& + &": {
                           mt: 2,
                        },
                     }}
                  >
                     <LocationOnIcon fontSize="small" />
                     {!location ? (
                        <Skeleton
                           sx={{ flexGrow: 1, ml: 1 }}
                           variant="rounded"
                           animation="wave"
                        />
                     ) : (
                        <Typography
                           noWrap
                           sx={{ ml: 1 }}
                           color="textPrimary"
                           variant="subtitle2"
                        >
                           {location.listFormatAddress}
                        </Typography>
                     )}
                  </Card>
               </Box>
               <Grid alignItems="center"
container>
                  <Grid item
xs={12}
sm={12}>
                     {location ? (
                        <LocationMap
                           locationKey={location.locationKey}
                           lat={location.latitude}
                           lon={location.longitude}
                           showAddBusiness={true}
                        ></LocationMap>
                     ) : (
                        <></>
                     )}
                  </Grid>
               </Grid>
               {!businesses ? (
                  <Skeleton
                     variant="rounded"
                     animation="wave"
                     height={300}
                     sx={{ mt: 4 }}
                  />
               ) : (
                  <BusinessList businesses={businesses} />
               )}
            </Container>
         </Box>
      </>
   );
};

Location.getLayout = (page) => <MarketingLayout>{page}</MarketingLayout>;

export default Location;

export const getServerSideProps: GetServerSideProps = async ({
   params,
   req,
}) => {
   const api = new API(new QueryClient(), req.headers.cookie);
   const locationKey = parseInt(params.locationKey as string);

   await Promise.all([
      api.prefetchGetLocation({ locationKey }),
      api.prefetchGetLocationBusinesses({ locationKey }),
      api.prefetchGetUser(),
   ]);

   return {
      props: {
         dehydratedState: dehydrate(api.queryClient),
      },
   };
};
