import { FunctionComponent } from "react";
import {
   Box,
   Button,
   Card,
   CardContent,
   CardHeader,
   Link,
   Typography,
} from "@mui/material";
import BusinessUnionList from "./business-union-list";
import { useRouter } from "next/router";
import { LocationToBusiness } from "../../slices/models";
import { Business as BusinessIcon } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import { paths } from "../../paths";
import { RouterLink } from "../../components/router-link";
import Image from "next/image";
interface Props {
   setBusinessCount?: any;
   setUnionsCount?: any;
   businesses: LocationToBusiness[];
}

const BusinessList: FunctionComponent<Props> = ({ businesses }) => {
   const router = useRouter();
   const locationKey = parseInt(router.query.locationKey as string);

   if (!businesses) return <></>;

   return (
      <>
         {businesses.map((business) => (
            <Card key={business.business.businessKey}
sx={{ mt: 2 }}>
               <CardHeader
                  sx={{ pb: 0 }}
                  action={
                     <Link
                        component={RouterLink}
                        href={paths.createUnion(
                           locationKey,
                           business.businessKey,
                        )}
                     >
                        <Button
                           data-cy="create-union-button"
                           size={"small"}
                           startIcon={
                              <AddIcon fontSize="small"
sx={{ mr: 0 }} />
                           }
                        >
                           Union
                        </Button>
                     </Link>
                  }
                  title={
                     <Box
                        sx={{
                           display: "flex",
                           flexDirection: "row",
                           justifyContent: "flex-start",
                           alignItems: "center",
                        }}
                     >
                        <Link
                           component={RouterLink}
                           href={paths.business(
                              locationKey,
                              business.business.businessKey,
                           )}
                           color="textPrimary"
                           variant="h6"
                           data-cy="business-link"
                        >
                           {!business.business.logo ||
                           !business.business.metadataAdded ? (
                              <BusinessIcon
                                 sx={{ mr: 2 }}
                                 fontSize="large"
                              ></BusinessIcon>
                           ) : (
                              <Box
                                 sx={{
                                    height: "60px",
                                    width: "100px",
                                    minWidth: "100px",
                                    position: "relative",
                                    mr: 2,
                                 }}
                              >
                                 <Image
                                    src={business.business.logo}
                                    alt={business.business.name}
                                    fill={true}
                                    style={{ objectFit: "contain" }}
                                 />
                              </Box>
                           )}
                        </Link>
                        <div>
                           <Link
                              component={RouterLink}
                              href={paths.business(
                                 locationKey,
                                 business.business.businessKey,
                              )}
                              color="textPrimary"
                              variant="h6"
                              data-cy="business-link"
                           >
                              {business.business.name}
                           </Link>
                           <Typography
                              sx={{
                                 mt: 1,
                                 overflow: "hidden",
                                 textOverflow: "ellipsis",
                                 display: "-webkit-box",
                                 WebkitLineClamp: "3",
                                 WebkitBoxOrient: "vertical",
                              }}
                              variant="body2"
                           >
                              {business.business.description}
                           </Typography>
                        </div>
                     </Box>
                  }
               ></CardHeader>
               <CardContent sx={{ pt: 1 }}>
                  <BusinessUnionList
                     locationKey={business.locationKey}
                     businessKey={business.businessKey}
                     unions={[]
                        .concat(business.unions || [])
                        .concat(business.globalUnions || [])}
                  />
               </CardContent>
            </Card>
         ))}
      </>
   );
};

export default BusinessList;
